<template>
    <perfect-scrollbar style="max-height: 600px">
        <template v-for="(item, indexs) in listEmail">
            <div class="zm-unselect"
                :key="indexs"
                @click.exact.prevent="handleClickMailItem(item)"
                @click.ctrl="ctrlAddItemToList(item)"
                @click.shift="shiftAddItemToList(item)"
            >
                <ItemMailBox
                    :dataEmail="item"
                    :activeEmail="getActiveEmail(item)"
                    :key="`email_${indexs}_${generateUniqueId()}`"
                    :lastItem="indexs === listEmail.length - 1"
                    :view-only="true"
                />
            </div>
        </template>
        <template v-if="!listEmail || listEmail.length == 0">
            <el-empty :description="$t('zimbra.zhMsg.noResultsFound')"></el-empty>
        </template>
    </perfect-scrollbar>
</template>
<script>
import ZimbraMailService from "@/services/ZimbraMailService.js";
import ItemMailBox from "@/components/modules/email/mail-box/item-mail-box";
import CommonUtils from "@/utils/common-utils";
import _ from "lodash";
export default {
    name: "ListViewOnly",
    mixins: [CommonUtils],
    data() {
        return {
            listEmail: [],
            activeEmail: null,
            listActiveMail: [],
        };
    },
    components: {
        ItemMailBox
    },
    methods: {
        searchForMailSelector(formData) {
            ZimbraMailService.searchRequest(formData).then((searchRequest) => {
                const searchResponse =
                    this.getResponseBody(searchRequest)["SearchResponse"];
                let listEmail = searchResponse.m || [];
                listEmail = Array.isArray(listEmail) ? listEmail : [listEmail];
                this.listEmail = listEmail;
                this.listActiveMail = [];
            });
        },
        setListEmail(listEmail) {
            this.listEmail = listEmail.map((e, idx) => ({ ...e, index: idx}));
            this.listActiveMail = [];
        },
        handleClickMailItem(item) {
            this.activeEmail = item;
            this.listActiveMail = [item];
        },
        ctrlAddItemToList(item) {
            const idx = this.listActiveMail.findIndex(e => e.id === item.id);
            if (idx > -1) {
                this.listActiveMail.splice(idx, 1);
            } else {
                this.listActiveMail.push(item);
            }
        },
        shiftAddItemToList(item) {
            let indexs = item.index;
            if (this.listActiveMail.length === 0) {
                return;
            }
            this.listActiveMail = _.orderBy(this.listActiveMail, ['index'], ['asc']);
            let firstIndex = this.listActiveMail[0].index;
            if (firstIndex > item.index) {
                firstIndex = [indexs, indexs = firstIndex][0];
            }
            this.listActiveMail = this.listEmail.filter((e, idx) => idx >= firstIndex && idx <= indexs);
        },
        getActiveEmail(item) {
            return this.listActiveMail.find(e => e.id === item.id);
        },
        getListActiveEmail() {
            return this.listActiveMail;
        }
    },
};
</script>